import React from "react";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { formatError } from "helpers/error-helpers";

const ValidatedInput = ({
  name,
  component: Component,
  errors,
  fullWidth,
  size,
  ...rest
}) => (
  <FormControl fullWidth={fullWidth} error={name in errors} size={size}>
    <Component {...rest} size={size} error={name in errors} name={name} />
    {name in errors ? (
      <FormHelperText>{formatError(errors[name])}</FormHelperText>
    ) : (
      ""
    )}
  </FormControl>
);

ValidatedInput.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
};

ValidatedInput.defaultProps = {
  errors: {},
  fullWidth: true,
  size: "medium",
};

export default ValidatedInput;
