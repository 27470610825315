import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.inverse.bkg.primary,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.inverse.bkg.hover.white,
    },
  },
  primary: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  primaryIcon: {
    position: "absolute",
  },
  secondaryIcon: {
    zIndex: theme.zIndex.portalSecondaryIcon,
  },
  navLink: {
    color: "grey",
    textDecoration: "none",
  },
  selected: {
    color: theme.palette.inverse.content.primary,
    background: theme.palette.inverse.bkg.hover.white,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  nested: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(4),
    "&:hover": {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      "& .removeOnHover": {
        visibility: "hidden !important",
      },
    },
  },
  nestedSelected: {
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    paddingLeft: theme.spacing(4),
    "&:hover": {
      color: theme.palette.secondary.main,
      "& .removeOnHover": {
        visibility: "hidden !important",
      },
    },
  },
  smallNested: {
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  },
  smallNestedSelected: {
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  },
  drawerPaper: {
    background: theme.palette.inverse.bkg.primary,
    position: "relative",
    whiteSpace: "nowrap",
    width: 245,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    background: theme.palette.inverse.bkg.primary,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: 60,
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 20,
    height: "46px !important",
  },
  icon: {},
  badge: {
    "& span": { marginRight: "30px" },
  },
  badgeTotal: {
    paddingRight: "20px",
  },
  signOut: {
    position: "absolute",
    bottom: 5,
    color: theme.palette.secondary.main,
  },
  title: {
    flexGrow: 1,
    paddingLeft: 70,
  },
  hide: {
    display: "none",
  },
  listItemText: {
    fontSize: "0.95rem",
  },
  shiftedMenuButton: {
    marginLeft: "17px",
    position: "fixed",
    left: 0,
  },
}));
