import { ISSERVER } from "helpers/server-helpers";

/**
 * @description Accesses the local Storage object and adds a data item to it.
 *
 * @param {string}  key     The name of the key to create/update
 * @param {string}  value   The value for the given key
 *
 */
export const localStorageSet = (key, value) =>
  !ISSERVER && window.localStorage.setItem(key, JSON.stringify(value));

/**
 * @description Accesses the local Storage object and returns the given key's value.
 *
 * @param {string}  key     The name of the key for the desired value
 *
 * @return {null|any} Returns the key's value or null if key does not exist
 */
export const localStorageGet = (key) =>
  ISSERVER || !window.localStorage.getItem(key)
    ? null
    : JSON.parse(window.localStorage.getItem(key));

/**
 * @description Removes the local Storage object.
 *
 * @param {string}  key     The name of the key for the desired value
 *
 */
export const localStorageRemove = (key) =>
  !ISSERVER && window.localStorage.removeItem(key);
