import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@mui/material/Box";

import useStyles from "./Portal.styles";

const Portal = ({ sideMenu, additionalClass, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Box display="flex" data-cy="portal" {...rest}>
      {sideMenu}
      <main className={clsx(classes.content, additionalClass)}>{children}</main>
    </Box>
  );
};

Portal.propTypes = {
  children: PropTypes.node,
  sideMenu: PropTypes.node,
  additionalClass: PropTypes.node,
};

Portal.defaultProps = {
  children: "",
  sideMenu: "",
  additionalClass: "",
};

export default Portal;
