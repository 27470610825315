import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import Image from "components/image-containers/Image/Image.component";

import Badge from "@mui/material/Badge";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ToolTip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {
  localStorageGet,
  localStorageSet,
} from "helpers/local-storage-helpers";
import useUser from "hooks/useUser.hook";
import { signOut } from "requests/auth-api";
import { useSideMenu } from "context/sidemenu";
import { vinnLogomark, vinnLogotextWhite } from "assets/externalImages";
import { checkAuth } from "helpers/auth-helper";

import ExpandIcon from "components/menus/SideMenu/ExpandIcon.component";
import CustomNextLink from "components/links/CustomNextLink/CustomNextLink.component";

import { useStyles } from "./SideMenu.styles";

const SideMenu = ({ defaultLinks }) => {
  const classes = useStyles();
  const { sideMenu, setSideMenu } = useSideMenu();
  const router = useRouter();
  const pathname = router.asPath;
  const [links, setLinks] = useState(defaultLinks);
  const { user, setUser } = useUser();

  const handleClick = (clickedItem) => {
    if (clickedItem.subMenu) {
      localStorageSet(clickedItem.name, !localStorageGet(clickedItem.name));
      setLinks(
        links.map((item) =>
          clickedItem.name === item.name
            ? {
                ...item,
                open: !clickedItem.open,
              }
            : item,
        ),
      );
    }
  };

  const nestedClass = (element) => {
    if (!sideMenu) {
      if (pathname === element.url) {
        return classes.nestedSelected;
      }
      return classes.nested;
    }
    if (pathname === element.url) {
      return classes.smallNestedSelected;
    }
    return classes.smallNested;
  };

  const selected = (item) =>
    pathname === item.url || (pathname === "/" && item.name === "Vehicle");

  const handleSignOut = () => {
    signOut().finally(() => {
      setUser();
    });
  };

  useEffect(() => {
    setLinks(defaultLinks);
  }, [defaultLinks]);

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      classes={{
        paper: clsx(classes.drawerPaper, sideMenu && classes.drawerPaperClose),
      }}
      onMouseEnter={() => setSideMenu(false)}
      onMouseLeave={() => setSideMenu(true)}
      open={sideMenu}
      className={classes.drawer}
      data-cy="side-menu"
    >
      <div className={classes.toolbarIcon}>
        <Image
          width={24}
          height={24}
          src={vinnLogomark}
          alt="logo"
          className={classes.shiftedMenuButton}
        />
        <Typography
          component="h1"
          variant="h6"
          noWrap
          className={!sideMenu ? classes.title : classes.hide}
        >
          <Image
            width={63}
            height={24}
            src={vinnLogotextWhite}
            alt="logomark"
          />
        </Typography>
      </div>
      {links.map(
        (item) =>
          !item.hide &&
          (!item.resource ||
            checkAuth(
              item.resource,
              user,
              item.authLevel || "read_access",
            )) && (
            <CustomNextLink
              item={item}
              href={item.url}
              key={item.name}
              className={classes.navLink}
              data-cy={item.cy}
              dontUnderlineText
            >
              <ListItem
                button
                className={selected(item) ? classes.selected : classes.root}
                onClick={() => {
                  handleClick(item);
                }}
              >
                <>
                  <Badge
                    badgeContent={item.badge?.notifications}
                    className={classes.badge}
                    color={item.badge?.colour || "primary"}
                    data-cy="side-menu-badge"
                  >
                    <ToolTip title={item.name} placement="bottom">
                      <ListItemIcon className={classes.secondaryIcon}>
                        <item.icon
                          color="secondary"
                          className="removeOnHover"
                        />
                      </ListItemIcon>
                    </ToolTip>
                    <ToolTip title={item.name} placement="bottom">
                      <ListItemIcon className={classes.primaryIcon}>
                        <item.icon
                          color={selected(item) ? "secondary" : "primary"}
                        />
                      </ListItemIcon>
                    </ToolTip>
                  </Badge>
                </>
                <ListItemText classes={{ primary: classes.listItemText }}>
                  {item.name}
                </ListItemText>
                {item.subMenu && <ExpandIcon open={item.open} />}
              </ListItem>
              {item.subMenu && (
                <Collapse
                  in={localStorageGet(item.name)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subMenu.map(
                      (element) =>
                        !element.hidden && (
                          <>
                            <CustomNextLink
                              href={element.url}
                              key={element.name}
                              className={classes.navLink}
                              data-cy={element.cy}
                              dontUnderlineText
                            >
                              <ListItem
                                button
                                className={nestedClass(element)}
                                title={element.name}
                              >
                                <Badge
                                  badgeContent={element.badge}
                                  className={classes.badge}
                                  color="primary"
                                >
                                  <ToolTip
                                    title={element.name}
                                    placement="bottom"
                                  >
                                    <ListItemIcon
                                      className={classes.secondaryIcon}
                                    >
                                      <element.icon
                                        color={
                                          selected(element)
                                            ? "primary"
                                            : "secondary"
                                        }
                                        className="removeOnHover"
                                      />
                                    </ListItemIcon>
                                  </ToolTip>
                                  <ToolTip
                                    title={element.name}
                                    placement="bottom"
                                  >
                                    <ListItemIcon
                                      className={classes.primaryIcon}
                                    >
                                      <element.icon
                                        color={
                                          selected(element)
                                            ? "secondary"
                                            : "primary"
                                        }
                                      />
                                    </ListItemIcon>
                                  </ToolTip>
                                </Badge>
                                <ListItemText
                                  classes={{ primary: classes.listItemText }}
                                >
                                  {element.name}
                                  <Badge
                                    badgeContent={element.textBadge}
                                    className={classes.badgeTotal}
                                    color="secondary"
                                  />
                                </ListItemText>
                              </ListItem>
                            </CustomNextLink>
                          </>
                        ),
                    )}
                  </List>
                </Collapse>
              )}
            </CustomNextLink>
          ),
      )}
      <ListItem
        className={classes.signOut}
        onClick={() => handleSignOut()}
        button
        data-cy="sign-out-button"
      >
        <ListItemIcon>
          <MeetingRoomIcon color="secondary" />
        </ListItemIcon>
        <ListItemText
          primary="Sign Out"
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
    </Drawer>
  );
};

SideMenu.propTypes = {
  defaultLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.elementType,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          icon: PropTypes.elementType,
          url: PropTypes.string,
          cy: PropTypes.string,
        }),
      ),
      cy: PropTypes.string,
      open: PropTypes.bool,
      label: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
};

SideMenu.defaultProps = {
  defaultLinks: [],
};

export default SideMenu;
