import React from "react";
import PropTypes from "prop-types";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ExpandIcon = ({ open }) => (open ? <ExpandLess /> : <ExpandMore />);

ExpandIcon.propTypes = {
  open: PropTypes.bool,
};

ExpandIcon.defaultProps = {
  open: false,
};

export default ExpandIcon;
