// variation of https://github.com/vvo/iron-session/blob/main/examples/next.js/lib/useUser.js
import { useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { UserContext } from "context/user";

const useUser = ({ redirectTo = "", redirectIfFound = false } = {}) => {
  const context = useContext(UserContext);
  const router = useRouter();
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  const [user, setUser] = context;

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo) return;

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user)
    ) {
      router.push(redirectTo);
    }
  }, [user, redirectIfFound, redirectTo]);

  return {
    user,
    setUser,
  };
};

export default useUser;
