import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: ({ color, dontUnderlineText }) => ({
    color: color || "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: dontUnderlineText ? "none" : "underline",
    },
  }),
}));
