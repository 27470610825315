import { capitalizeFirstLetter } from "helpers/string-format-helpers";

/**
 * @description Takes an array of error message and returns them period separated
 * with the first letter of each capitalized.
 *
 *
 * @param {Array<string>}  errorList  An array of error messages (strings).
 *
 * @return {string} A string with the first letter of each error capitalized and joined by a period.
 */
export const formatError = (errorList) =>
  errorList.map((error) => capitalizeFirstLetter(error)).join(". ");
