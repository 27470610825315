import React from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import clsx from "clsx";

import { useStyles } from "./CustomNextLink.styles";

const CustomNextLink = ({
  href,
  children,
  color,
  dontUnderlineText,
  className,
  ...rest
}) => {
  const classes = useStyles({ color, dontUnderlineText });

  return (
    <Link href={href} className={clsx(className, classes.root)} {...rest}>
      {children}
    </Link>
  );
};

CustomNextLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  dontUnderlineText: PropTypes.bool,
};

CustomNextLink.defaultProps = {
  color: undefined,
  className: "",
  dontUnderlineText: false,
};

export default CustomNextLink;
